import PaymentMethodSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.0.24/index.vue';
import creditCurrentBillDetail from '@/lib/data-service/default/web_credit_creditDetailList'
import creditNotRefund from '@/lib/data-service/default/web_credit_creditNotRefund'
export default {
  data() {
    return {
      detail: {},
      loading: false,
      amount: 0,
      radio: '',
      orderList: '',
      formData: {
        refundType: 1,
        orderAmount: 0,
        billIds: ''
      }
    }
  },
  components: {
    PaymentMethodSelector,
  },
  methods: {
    newPaySubmit() {
      const __this = this;
      __this.formData = Object.assign(__this.formData, {
        companyId: __this.detail.results[0].companyId
      })
      console.log(this.formData)
      __this.$refs.aPaymentMethodSelector.init({
        // situationParameter: {
        //   "100": {
        //     refundType: __this.$route.query.type || 1,//还款类型：1.一键还款，2.主账单还款，3.子账单还款
        //     orderAmount: __this.amount,//订单金额，用于计算可以用哪些支付方式支付
        //     billIds: __this.orderList,
        //   }
        // },
        getSituationParameter() {
          // const o = {
          //   '100': {
          //     refundType: 1,//还款类型：1.一键还款，2.主账单还款，3.子账单还款
          //     orderAmount: 0.01,//订单金额，用于计算可以用哪些支付方式支付
          //     billIds: `${moment().format('YYYY-MM-DD HH:mm:ss')}`,
          //   },
          // }
          let s = {
            '100': __this.formData
          };
          return s
        },
        before_pay_event_handler() {
          // let list = __this.detail.results.reduce((newArr, item) => {
          //   if (item.statuOrder) {
          //     newArr.push(item.id);
          //   }
          //   return newArr;
          // }, [])
          // if (list.length == 0) {
          //   __this.$message({ type: "warning", message: "请选择账单！" })
          //   return Promise.reject('error')
          // } else {
          //   __this.formData.billIds = list.join(',')
          //   return Promise.resolve('success')
          // }
        },

        //支付成功事件处理方法
        pay_successful_event_handler() {
          __this.$router.push({ name: 'distributor-credit-back-account' })
        },
        //支付失败事件处理方法
        pay_failed_event_handler() {
        },
      });
    },
    selectCheck() {
      this.formData.orderAmount = this.detail.results.reduce((num, item) => {
        if (item.statuOrder) {
          num += item.billAmount
        }
        return num;
      }, 0)
    },
    getDetail() {
      let billIds = this.$route.query.ids
      this.loading = true
      creditNotRefund({ billIds }).then(res => {
        res.results.map(item => {
          item.billStatus == 3 ? item.statuOrder = true : item.statuOrder = false
        })
        this.$nextTick(() => {
          this.detail = res
          this.loading = false
          this.formData.orderAmount = res.totalAmount
          this.newPaySubmit()
        })
      })
    }
  },
  activated() {
    this.formData.refundType = this.$route.query.type
    this.formData.billIds = this.$route.query.ids
    this.getDetail()
  }
}
